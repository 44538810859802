<template>
  <div>
    <div slot="header" style="position: relative">
      <div class="query">
        <el-input
          style="margin-left: 0px"
          clearable
          :placeholder="showContents.numbering"
          v-model="queryData.numbering"
          @keyup.enter.native="getDataList(true)"
        ></el-input>
        <el-input
          style="width: 300px"
          clearable
          :placeholder="showContents.keyWord"
          v-model="queryData.keyWord"
          @keyup.enter.native="getDataList(true)"
        ></el-input>
        <el-button type="success" class="ml10" @click="getDataList(true)">{{
          showContents.queryBtn
        }}</el-button>

        <!-- <el-button type="primary" @click="exportData1()">{{
          showContents.dcbgBtn
        }}</el-button> -->
        <el-button type="primary" @click="exportData2()">{{
          showContents.dcxzdbgBtn
        }}</el-button>
        <el-button type="primary" @click="exportData3()">{{
          showContents.dcxzdyhsjBtn
        }}</el-button>
      </div>
    </div>

    <el-table
      :data="listData"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      border
      stripe
      style="margin-top: 20px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        prop="numbering"
        :label="showContents.tableBarCode"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.numbering }}
          <el-button
            type="text"
            v-if="isAuth('修改条码')"
            @click="editNumberIng(scope.row.id)"
            >{{ showContents.tableBarCodeBtn }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        :label="showContents.tableUserName"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="sex"
        :label="showContents.tableSex"
        min-width="100"
      >
        <template slot-scope="scope">
          <el-tag>{{ $store.state.language == "en" ? "Male" : "男" }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="age"
        :label="showContents.tableAge"
        min-width="100"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.age }}{{ showContents.tableAgeUnit }}</p>
        </template>
      </el-table-column>

      <el-table-column
        prop="tex101"
        :label="`tex101${showContents.valueTxt}`"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="proAKAp4"
        :label="`proAKAp4${showContents.valueTxt}`"
        min-width="120"
        v-if="$store.state.language == 'zh'"
      ></el-table-column>
      <el-table-column
        prop="dfi"
        :label="`dfi${showContents.valueTxt}`"
        min-width="120"
        v-if="$store.state.language == 'zh'"
      ></el-table-column>
      <!-- <el-table-column
        prop="sex"
        :label="showContents.tableLanguage"
        min-width="100"
      >
        <template slot-scope="scope">
          <el-tag
            :type="
              scope.row.proAKAp4 === 0 &&
              scope.row.dfi === 0 
                ? 'success'
                : ''
            "
            >{{
              scope.row.proAKAp4 === 0 &&
              scope.row.dfi === 0 
                ? showContents.tableLanguageTxt2
                : showContents.tableLanguageTxt1
            }}</el-tag
          >
        </template>
      </el-table-column> -->
      <el-table-column :label="showContents.detectionTime" min-width="160">
        <template slot-scope="scope">
          <p v-if="scope.row.detectionTime">
            {{ scope.row.detectionTime | dateFormat1 }}
          </p>
          <el-tag v-else>{{ showContents.detectionTimeTxt }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="showContents.createTime" min-width="160">
        <template slot-scope="scope">
          <p>{{ scope.row.createTime | dateFormat1 }}</p>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        prop="operation"
        :label="showContents.operation"
        width="220px;"
      >
        <template slot-scope="scope">
          <div class="tableOperation">
            <el-button type="text" @click="openUrl(scope.row)">{{
              showContents.checkBtn
            }}</el-button>

            <span class="operationLine">|</span>
            <el-button type="text" @click="downloadReport(scope.row)">{{
              showContents.downloadReportBtn
            }}</el-button>
            <span class="operationLine">|</span>
            <el-button class="F56C6C" type="text" @click="del(scope.row.id)">{{
              showContents.deleteBtn
            }}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <el-pagination
      class="floatRight"
      v-if="total > 10"
      @size-change="getDataListSizeChange"
      @current-change="getDataListCurrentChange"
      :current-page.sync="queryData.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="queryData.size"
      :total="total"
      layout="total,sizes, prev, pager, next"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 10,
        current: 1,
        sex: "男",
      },
      showContents: {
        keyWord: "请输入昵称/手机号/邮箱",
        numbering: "条码号",
        queryBtn: "查询",
        dcxzdbgBtn: "导出选中的报告",
        dcxzdyhsjBtn: "导出选中的用户数据",
        tableBarCode: "条码号",
        tableBarCodeBtn: "修改",
        tableUserName: "姓名",
        tableSex: "性别",
        tableAge: "年龄",
        tableAgeUnit: "岁",
        valueTxt: "值",
        tableLanguage: "语言",
        tableLanguageTxt1: "中文",
        tableLanguageTxt2: "英文",
        detectionTimeTxt: "待检测",
        detectionTime: "报告时间",
        createTime: "创建时间",
        checkBtn: "查看",
        downloadReportBtn: "下载报告",
        deleteBtn: "删除",
        operation: "操作",
      },
      czsbqcshlxgly:
        this.$store.state.language == "zh"
          ? "操作失败，请重试或联系管理员"
          : "Operation failed, please try again or contact the administrator",
    };
  },
  created() {},
  methods: {
    editNumberIng(id) {
      this.$prompt("请输入新条码", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$http
            .put(`user-test-data/updateNumberingById`, {
              id,
              numbering: value,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.getDataList();
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消修改",
          });
        });
    },
    init() {
      if (this.getUserRoleName() == "客户") {
        this.$set(this.queryData, "userLoginId", this.getQueryId());
      }
      this.getDataList();
      if (this.$store.state.language == "en") {
        this.showContents = {
          keyWord: "Please enter your username/phone number/email",
          numbering: "barCode",
          queryBtn: "query",
          dcxzdbgBtn: "Export the selected report",
          dcxzdyhsjBtn: "Export selected user data",
          tableBarCode: "barCode",
          tableBarCodeBtn: "edit",
          tableUserName: "userName",
          tableSex: "sex",
          tableAge: "age",
          tableAgeUnit: "year",
          valueTxt: "value",
          tableLanguage: "language",
          tableLanguageTxt1: "Chinese",
          tableLanguageTxt2: "English",
          detectionTime: "Report time",
          detectionTimeTxt: "To be tested",
          createTime: "Creation time",
          checkBtn: "check",
          downloadReportBtn: "download",
          deleteBtn: "delete",
          operation: "operation",
        };
      }
    },

    handleSelectionChange(e) {
      this.selectionList = e;
    },
    exportData3() {
      if (this.selectionList.length > 0) {
        const downloadLoading = this.$loading({
          lock: true,
          text: "正在导出，数据量过大时请耐心等待......",
          spinner: "el-icon-loading",
        });
        let list = this.selectionList;
        let ids = [];
        list.forEach((item) => {
          ids.push(item.id);
        });
        this.$http
          .post(`/user-test-data/exportUserData`, ids, {
            responseType: "blob",
          })
          .then((res) => {
            if (res.data != null) {
              // 处理响应正文responseText，多数是json数据
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });

              let url = window.URL.createObjectURL(blob);
              const link = document.createElement("a"); // 创建a标签
              link.href = url;
              link.download =
                this.getTodayTime2(new Date()) + "用户数据" + ".xlsx"; // 重命名文件
              link.click();
              URL.revokeObjectURL(url); // 释放内存
            } else
              this.$message({
                message: this.czsbqcshlxgly,
                type: "warning",
              });
            downloadLoading.close();
          })
          .catch((err) => {
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
            downloadLoading.close();
          });
      } else
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
        });
    },
    exportData2() {
      if (this.selectionList.length > 0) {
        const downloadLoading = this.$loading({
          lock: true,
          text: "正在导出，数据量过大时请耐心等待......",
          spinner: "el-icon-loading",
        });
        let list = this.selectionList;
        let ids = [];
        list.forEach((item) => {
          ids.push(item.id);
        });
        this.$http
          .post(`/user-test-data/exportPdfByIds`, ids, {
            responseType: "blob",
          })
          .then((res) => {
            if (res.data != null) {
              // 处理响应正文responseText，多数是json数据
              let blob = new Blob([res.data], {
                type: "application/zip",
              });

              let url = window.URL.createObjectURL(blob);
              const link = document.createElement("a"); // 创建a标签
              link.href = url;
              link.download =
                this.getTodayTime2(new Date()) + "男性检测数据" + ".zip"; // 重命名文件
              link.click();
              URL.revokeObjectURL(url); // 释放内存
            } else
              this.$message({
                message: this.czsbqcshlxgly,
                type: "warning",
              });
            downloadLoading.close();
          })
          .catch((err) => {
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
            downloadLoading.close();
          });
      } else
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
        });
    },
    exportData1() {
      const downloadLoading = this.$loading({
        lock: true,
        text: "正在导出，数据量过大时请耐心等待......",
        spinner: "el-icon-loading",
      });
      let data = this.queryData;
      if (this.startTimeAndEndTime && this.startTimeAndEndTime.length > 0) {
        let timeArr = this.startTimeAndEndTime;
        data.startTime = timeArr[0];
        data.endTime = timeArr[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      this.$http
        .get(
          `user-test-data/exportPdf?startTime=${encodeURIComponent(
            data.startTime
          )}&endTime=${encodeURIComponent(
            data.endTime
          )}&sex=${encodeURIComponent(data.sex)}&userLoginId=${
            data.userLoginId
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res.data != null) {
            // 处理响应正文responseText，多数是json数据
            let blob = new Blob([res.data], {
              type: "application/zip",
            });

            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            link.download =
              this.getTodayTime2(new Date()) + "男性检测数据" + ".zip"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url); // 释放内存
          } else
            this.$message({
              message: this.czsbqcshlxgly,
              type: "warning",
            });
          downloadLoading.close();
        })
        .catch((err) => {
          this.$message({
            message: this.czsbqcshlxgly,
            type: "warning",
          });
          downloadLoading.close();
        });
    },
    downloadReport(row) {
      let url = "";
      if (location.host.indexOf("admin.predicthealthen.com") !== -1)
        url = `https://api.predicthealth.cn/user-test-data/downloadReport/${row.id}?version=EN`;
      else {
        url = `https://api.predicthealth.cn/user-test-data/downloadReport/${row.id}`;
      }

      window.open(`${url}`);
    },
    openUrl(row) {
      let url = "";
      // url= "http://192.168.1.41:4000"
      // if (!location.port)
      if (location.host.indexOf("admin.predicthealthen.com") !== -1) {
        url = `https://en.predicthealth.cn`;
      } else {
        url = `https://www.predicthealth.cn`;
      }
      // if (row.proAKAp4 === 0 && row.dfi === 0)
      //   url = `https://en.predicthealth.cn`;
      // else {
      //   url = `https://www.predicthealth.cn`;
      // }
      window.open(`${url}/MaleHealth?id=${row.id}`);
    },
    /**
     * 删除数据
     */
    del(id) {
      if (id) {
        this.$confirm(
          this.$store.state.language == "en"
            ? "Do you want to delete the selected data？"
            : "是否删除所选择的数据?",

          this.$store.state.language == "en" ? "Kind reminder" : "温馨提示",
          {
            confirmButtonText:
              this.$store.state.language == "en" ? "confirm" : "确定",
            cancelButtonText:
              this.$store.state.language == "en" ? "cancel" : "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$http
              .delete(`user-test-data/baseDeleteById/${id}`)
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message:
                      this.$store.state.language == "en"
                        ? "Delete successful"
                        : "删除成功",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message:
                this.$store.state.language == "en"
                  ? "Cancel operation"
                  : "取消操作",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.queryData.current = 1;
      this.loading = true;
      let data = this.queryData;
      if (data.keyWord) data.keyWord = data.keyWord.trim();
      this.$http
        .get(`user-test-data/getSelectPage`, {
          params: data,
        })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            }
            this.loading = false;
          }
        });
    },
    getDataListSizeChange(val) {
      this.queryData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.queryData.current = val;
      this.getDataList();
    },
  },
};
</script>

<style lang='less' scoped>
</style>
